import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { CalendarEventConfiguration } from '../../models/CalendarEventConfiguration';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  calendarEventConfigurationCreated,
  calendarEventConfigurationDeleted,
  calendarEventConfigurationNotCreated,
  calendarEventConfigurationNotDeleted,
  calendarEventConfigurationNotUpdated,
  calendarEventConfigurationsDeleted,
  calendarEventConfigurationsNotDeleted,
  calendarEventConfigurationUpdated,
  calendarEventsFetched,
  calendarEventsNotFetched,
  createCalendarEventConfiguration,
  deleteCalendarEventConfiguration,
  deleteCalendarEventConfigurations,
  fetchCalendarEvents,
  updateCalendarEventConfiguration,
} from './CalendarEventConfigurations.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createCalendarEventConfigurationSaga() {
  yield takeEvery(createCalendarEventConfiguration.type, createCalendarEventConfigurationRequest);
}

function* calendarEventConfigurationCreatedSaga() {
  yield takeLatest(calendarEventConfigurationCreated.type, createCalendarEventConfigurationResponse);
}

function* calendarEventConfigurationkNotCreatedSaga() {
  yield takeLatest(calendarEventConfigurationNotCreated.type, createCalendarEventConfigurationAError);
}

// Request
function* createCalendarEventConfigurationRequest(action: PayloadAction<CalendarEventConfiguration>) {
  try {
    const { payload: calendarEventConfiguration } = action;
    yield apiService.execute({
      url: 'CalendarEventConfigurations',
      method: ApiRequestType.POST,
      data: calendarEventConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: calendarEventConfigurationNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createCalendarEventConfigurationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('calendarEventConfigurations.notifications.create');
}

// Error
function createCalendarEventConfigurationAError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'calendarEventConfigurations.notifications.createFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateCalendarEventConfigurationSaga() {
  yield takeEvery(updateCalendarEventConfiguration.type, updateCalendarEventConfigurationRequest);
}

function* calendarEventConfigurationUpdatedSaga() {
  yield takeLatest(calendarEventConfigurationUpdated.type, updateCalendarEventConfigurationResponse);
}

function* calendarEventConfigurationNotUpdatedSaga() {
  yield takeLatest(calendarEventConfigurationNotUpdated.type, updateCalendarEventConfigurationError);
}

// Request
function* updateCalendarEventConfigurationRequest(action: PayloadAction<CalendarEventConfiguration>) {
  try {
    const { payload: calendarEventConfiguration } = action;
    yield apiService.execute({
      url: `CalendarEventConfigurations/${calendarEventConfiguration.Id}`,
      method: ApiRequestType.PUT,
      data: calendarEventConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: calendarEventConfigurationNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateCalendarEventConfigurationResponse() {
  notificationService.showSuccess('calendarEventConfigurations.notifications.update');
}

// Error
function updateCalendarEventConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'calendarEventConfigurations.notifications.updateFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteCalendarEventConfigurationSaga() {
  yield takeEvery(deleteCalendarEventConfiguration.type, deleteCalendarEventConfigurationRequest);
}

function* calendarEventConfigurationDeletedSaga() {
  yield takeLatest(calendarEventConfigurationDeleted.type, deleteCalendarEventConfigurationResponse);
}

function* calendarEventConfigurationNotDeletedSaga() {
  yield takeLatest(calendarEventConfigurationNotDeleted.type, deleteKCalendarEventConfigurationError);
}

// Request
function* deleteCalendarEventConfigurationRequest(action: PayloadAction<CalendarEventConfiguration>) {
  try {
    const { payload: calendarEventConfiguration } = action;
    yield apiService.execute({
      url: `CalendarEventConfigurations/${calendarEventConfiguration.Id}`,
      method: ApiRequestType.DELETE,
      data: calendarEventConfiguration,
    });
  } catch ({ message }) {
    yield put({ type: calendarEventConfigurationNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteCalendarEventConfigurationResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('calendarEventConfigurations.notifications.delete');
  action.payload.history.push(`/CalendarEventConfigurations`);
}

// Error
function deleteKCalendarEventConfigurationError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'calendarEventConfigurations.notifications.deleteFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteCalendarEventConfigurationsSaga() {
  yield takeEvery(deleteCalendarEventConfigurations.type, deleteCalendarEventConfigurationsRequest);
}

function* calendarEventConfigurationsDeletedSaga() {
  yield takeLatest(calendarEventConfigurationsDeleted.type, deleteCalendarEventConfigurationsResponse);
}

function* calendarEventConfigurationsNotDeletedSaga() {
  yield takeLatest(calendarEventConfigurationsNotDeleted.type, deleteCalendarEventConfigurationsError);
}

// Request
function* deleteCalendarEventConfigurationsRequest(action: PayloadAction<Array<CalendarEventConfiguration>>) {
  try {
    const { payload: calendarEventConfigurations } = action;
    yield apiService.execute({
      url: `CalendarEventConfigurations`,
      method: ApiRequestType.DELETE,
      data: { ids: calendarEventConfigurations.map((calendarEventConfiguration) => calendarEventConfiguration.Id) },
    });
  } catch ({ message }) {
    yield put({ type: calendarEventConfigurationsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteCalendarEventConfigurationsResponse() {
  notificationService.showSuccess('calendarEventConfigurations.notifications.deleteAll');
}

// Error
function deleteCalendarEventConfigurationsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'calendarEventConfigurations.notifications.deleteAllFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* FETCH *********************

// Worker Sagas
function* fetchCalendarEventsSaga() {
  yield takeEvery(fetchCalendarEvents.type, fetchCalendarEventsRequest);
}

function* calendarEventsFetchedSaga() {
  yield takeLatest(calendarEventsFetched.type, fetchCalendarEventsResponse);
}

function* calendarEventsNotFetchedSaga() {
  yield takeLatest(calendarEventsNotFetched.type, fetchCalendarEventsError);
}

// Request
function* fetchCalendarEventsRequest(action: PayloadAction<CalendarEventConfiguration>) {
  try {
    const { payload: calendarEventConfiguration } = action;
    yield apiService.execute({
      url: `CalendarEventConfigurations/Fetch/${calendarEventConfiguration.Id}`,
      method: ApiRequestType.POST,
    });
  } catch ({ message }) {
    yield put({ type: calendarEventsNotFetched.type, payload: { msg: { message } } });
  }
}

// Response
function fetchCalendarEventsResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('calendarEventConfigurations.notifications.fetch');
}

// Error
function fetchCalendarEventsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'calendarEventConfigurations.notifications.fetchFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createCalendarEventConfigurationSaga(),
    calendarEventConfigurationCreatedSaga(),
    calendarEventConfigurationkNotCreatedSaga(),
    // Update
    updateCalendarEventConfigurationSaga(),
    calendarEventConfigurationUpdatedSaga(),
    calendarEventConfigurationNotUpdatedSaga(),
    // Delete
    deleteCalendarEventConfigurationSaga(),
    calendarEventConfigurationDeletedSaga(),
    calendarEventConfigurationNotDeletedSaga(),
    // Delete All
    deleteCalendarEventConfigurationsSaga(),
    calendarEventConfigurationsDeletedSaga(),
    calendarEventConfigurationsNotDeletedSaga(),
    // Fetch
    fetchCalendarEventsSaga(),
    calendarEventsFetchedSaga(),
    calendarEventsNotFetchedSaga(),
  ]);
}
