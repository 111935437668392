import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { AzureTenant } from '../../models/AzureTenant';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  azureTenantCreated,
  azureTenantDeleted,
  azureTenantImported,
  azureTenantNotCreated,
  azureTenantNotDeleted,
  azureTenantNotImported,
  azureTenantNotUpdated,
  azureTenantsDeleted,
  azureTenantsNotDeleted,
  azureTenantUpdated,
  createAzureTenant,
  deleteAzureTenant,
  deleteAzureTenants,
  importAzureTenant,
  updateAzureTenant,
} from './AzureTenants.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createAzureTenantSaga() {
  yield takeEvery(createAzureTenant.type, createAzureTenantRequest);
}

function* azureTenantCreatedSaga() {
  yield takeLatest(azureTenantCreated.type, createAzureTenantResponse);
}

function* azureTenantNotCreatedSaga() {
  yield takeLatest(azureTenantNotCreated.type, createAzureTenantAError);
}

// Request
function* createAzureTenantRequest(action: PayloadAction<AzureTenant>) {
  try {
    const { payload: azureTenant } = action;
    yield apiService.execute({
      url: 'AzureTenants',
      method: ApiRequestType.POST,
      data: azureTenant,
    });
  } catch ({ message }) {
    yield put({ type: azureTenantNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createAzureTenantResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('azureTenants.notifications.create');
}

// Error
function createAzureTenantAError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('azureTenants.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateAzureTenantSaga() {
  yield takeEvery(updateAzureTenant.type, updateAzureTenantRequest);
}

function* azureTenantUpdatedSaga() {
  yield takeLatest(azureTenantUpdated.type, updateAzureTenantResponse);
}

function* azureTenantNotUpdatedSaga() {
  yield takeLatest(azureTenantNotUpdated.type, updateAzureTenantError);
}

// Request
function* updateAzureTenantRequest(action: PayloadAction<AzureTenant>) {
  try {
    const { payload: azureTenant } = action;
    yield apiService.execute({
      url: `AzureTenants/${azureTenant.Id}`,
      method: ApiRequestType.PUT,
      data: azureTenant,
    });
  } catch ({ message }) {
    yield put({ type: azureTenantNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateAzureTenantResponse() {
  notificationService.showSuccess('azureTenants.notifications.update');
}

// Error
function updateAzureTenantError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('azureTenants.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteAzureTenantSaga() {
  yield takeEvery(deleteAzureTenant.type, deleteAzureTenantRequest);
}

function* azureTenantDeletedSaga() {
  yield takeLatest(azureTenantDeleted.type, deleteAzureTenantResponse);
}

function* azureTenantNotDeletedSaga() {
  yield takeLatest(azureTenantNotDeleted.type, deleteKAzureTenantError);
}

// Request
function* deleteAzureTenantRequest(action: PayloadAction<AzureTenant>) {
  try {
    const { payload: azureTenant } = action;
    yield apiService.execute({
      url: `AzureTenants/${azureTenant.Id}`,
      method: ApiRequestType.DELETE,
      data: azureTenant,
    });
  } catch ({ message }) {
    yield put({ type: azureTenantNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteAzureTenantResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('azureTenants.notifications.delete');
  action.payload.history.push(`/AzureTenants`);
}

// Error
function deleteKAzureTenantError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('azureTenants.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteAzureTenantsSaga() {
  yield takeEvery(deleteAzureTenants.type, deleteAzureTenantsRequest);
}

function* azureTenantsDeletedSaga() {
  yield takeLatest(azureTenantsDeleted.type, deleteAzureTenantsResponse);
}

function* azureTenantsNotDeletedSaga() {
  yield takeLatest(azureTenantsNotDeleted.type, deleteAzureTenantsError);
}

// Request
function* deleteAzureTenantsRequest(action: PayloadAction<Array<AzureTenant>>) {
  try {
    const { payload: azureTenants } = action;
    yield apiService.execute({
      url: `AzureTenants`,
      method: ApiRequestType.DELETE,
      data: { ids: azureTenants.map((azureTenant) => azureTenant.Id) },
    });
  } catch ({ message }) {
    yield put({ type: azureTenantsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteAzureTenantsResponse() {
  notificationService.showSuccess('azureTenants.notifications.deleteAll');
}

// Error
function deleteAzureTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('azureTenants.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* Import Employees *****************

// Worker Sagas
function* importAzureTenantSaga() {
  yield takeEvery(importAzureTenant.type, importAzureTenantsRequest);
}

function* azureTenantImportedSaga() {
  yield takeLatest(azureTenantImported.type, importAzureTenantsResponse);
}

function* azureTenantNotImportedSaga() {
  yield takeLatest(azureTenantNotImported.type, importAzureTenantsError);
}

// Request
function* importAzureTenantsRequest(action: PayloadAction<AzureTenant>) {
  try {
    const { payload: azureTenant } = action;
    yield apiService.execute({
      url: `AzureTenants/Import/${azureTenant.Id}`,
      method: ApiRequestType.POST,
    });
  } catch ({ message }) {
    yield put({ type: azureTenantNotImported.type, payload: { msg: { message } } });
  }
}

// Response
function importAzureTenantsResponse() {
  notificationService.showSuccess('azureTenants.notifications.import');
}

// Error
function importAzureTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('azureTenants.notifications.importFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createAzureTenantSaga(),
    azureTenantCreatedSaga(),
    azureTenantNotCreatedSaga(),
    // Update
    updateAzureTenantSaga(),
    azureTenantUpdatedSaga(),
    azureTenantNotUpdatedSaga(),
    // Delete
    deleteAzureTenantSaga(),
    azureTenantDeletedSaga(),
    azureTenantNotDeletedSaga(),
    // Delete All
    deleteAzureTenantsSaga(),
    azureTenantsDeletedSaga(),
    azureTenantsNotDeletedSaga(),
    // Import Employees
    importAzureTenantSaga(),
    azureTenantImportedSaga(),
    azureTenantNotImportedSaga(),
  ]);
}
