import React from 'react';
import { MenuProps } from 'antd';

// Components
import { Translated } from '../../components/UI/Core/Translated';

// Constants
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { AppMenuItem } from '../../components/App/AppMenu/AppMenuItem';

export const menuItems: MenuProps['items'] = [
  {
    key: 'Dashboard',
    label: <AppMenuItem id="dashboard" to="/Dashboard" icon="data-display" />,
    className: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
  },

  {
    key: 'Management',
    label: <Translated id="appMenu.management" />,
    icon: <BasicIcon className="icon icon-lock-screen" />,
    popupClassName: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    children: [
      {
        key: 'IdentityUsers',
        label: <AppMenuItem id="identityUsers" to="/IdentityUsers" icon="auth-screen" />,
      },
      {
        key: 'ApiClients',
        label: <AppMenuItem id="apiClients" to="/ApiClients" icon="user" />,
      },
      {
        key: 'Tenants',
        label: <AppMenuItem id="tenants" to="/Tenants" icon="crm" />,
        popupClassName: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
        children: [
          {
            key: 'Companies',
            label: <AppMenuItem id="companies" to="/Companies" icon="company" />,
          },
          {
            key: 'Locations',
            label: <AppMenuItem id="locations" to="/Locations" icon="location" />,
          },
        ],
      },
    ],
  },

  {
    key: 'Configurations',
    label: <Translated id="appMenu.configurations" />,
    icon: <BasicIcon className="icon icon-extra-components" />,
    popupClassName: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
    children: [
      {
        key: 'ServiceConfigurations',
        label: <AppMenuItem id="serviceConfigurations" to="/ServiceConfigurations" icon="chart-radar" />,
      },
      {
        key: 'ApiConfigurations',
        label: <AppMenuItem id="apiConfigurations" to="/ApiConfigurations" icon="chart-radar" />,
      },
      {
        key: 'AccessProfiles',
        label: <AppMenuItem id="accessProfiles" to="/AccessProfiles" icon="select" />,
      },
      {
        key: 'IdentifierTypes',
        label: <AppMenuItem id="identifierTypes" to="/IdentifierTypes" icon="product-list" />,
      },
      {
        key: 'Kiosks',
        label: <AppMenuItem id="kiosks" to="/Kiosks" icon="apps" />,
      },
      {
        key: 'AzureTenants',
        label: <AppMenuItem id="azureTenants" to="/AzureTenants" icon="auth-screen" />,
      },
      {
        key: 'CalendarEventConfigurations',
        label: <AppMenuItem id="calendarEventConfigurations" to="/CalendarEventConfigurations" icon="email" />,
      },
      {
        key: 'ScheduleConfigurations',
        label: <AppMenuItem id="scheduleConfigurations" to="/ScheduleConfigurations" icon="schedule" />,
      },
      {
        key: 'Actions',
        label: <AppMenuItem id="actions" to="/Actions" icon="setting" />,
      },
    ],
  },
];
