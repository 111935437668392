import React, { useCallback, useMemo } from 'react';
import { Button, Divider, Form, Input, Space } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import {
  CalendarEventConfiguration,
  CalendarEventConfigurationFormValues,
} from '../../../models/CalendarEventConfiguration';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Actions
import { updateCalendarEventConfiguration } from '../../../store/CalendarEventConfigurations/CalendarEventConfigurations.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { CalendarEventConfigurationProtocolEnum } from '../../../models/enums/CalendarEventConfigurationProtocol';

const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0;

  & .ant-space-item {
    width: 100%;
  }
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0;
`;

// Styled
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Props
interface SettingsReduxProps {
  calendarEventConfiguration: CalendarEventConfiguration | undefined;
}

// Hook
export const useSettingsRedux = ({ calendarEventConfiguration }: SettingsReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ calendarEventConfigurations }) => calendarEventConfigurations?.updating ?? false);
  const error = useAppSelector(({ calendarEventConfigurations }) => calendarEventConfigurations?.error ?? false);

  // Form Values
  const initialValues: CalendarEventConfigurationFormValues = useMemo(
    () => ({
      Id: calendarEventConfiguration?.Id,
      Name: calendarEventConfiguration?.Name,
      Protocol: calendarEventConfiguration?.Protocol,
      EmailAddress: calendarEventConfiguration?.EmailAddress,
      ImapServerHost: calendarEventConfiguration?.ImapServerHost,
      ImapServerPort: calendarEventConfiguration?.ImapServerPort,
      AzureTenantId: calendarEventConfiguration?.AzureTenantId,
      ClientId: calendarEventConfiguration?.ClientId,
      ClientSecret: calendarEventConfiguration?.ClientSecret,
      Password: calendarEventConfiguration?.Password,
      TenantId: calendarEventConfiguration?.TenantId,
    }),
    [calendarEventConfiguration]
  );

  // Components
  const SettingsForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: CalendarEventConfiguration) => dispatch(updateCalendarEventConfiguration(values))}
      >
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="Protocol" hidden>
          <Input />
        </Item>
        <Item name="TenantId" hidden>
          <Input />
        </Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['ImapServerHost']}
            label={<Translated id="calendarEventConfigurations.imapServerHost" />}
          >
            <Input />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['ImapServerPort']}
            label={<Translated id="calendarEventConfigurations.imapServerPort" />}
            rules={[
              () => ({
                validator(_, value) {
                  const num = Number.parseInt(value, 10);
                  if (!Number.isNaN(num) && num >= 1 && num <= 65535) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'form.warnings.number' })));
                },
              }),
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['EmailAddress']}
            label={<Translated id="calendarEventConfigurations.emailAddress" />}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        {(initialValues.Protocol === CalendarEventConfigurationProtocolEnum.Gmail ||
          initialValues.Protocol === CalendarEventConfigurationProtocolEnum.Custom) && (
          <StyledSpace align="start">
            <NoSidePaddingFormItem name={['Password']} label={<Translated id="calendarEventConfigurations.password" />}>
              <Input />
            </NoSidePaddingFormItem>
          </StyledSpace>
        )}

        {initialValues.Protocol === CalendarEventConfigurationProtocolEnum.Outlook && (
          <>
            <StyledSpace align="start">
              <NoSidePaddingFormItem
                name={['AzureTenantId']}
                label={<Translated id="calendarEventConfigurations.azureTenantId" />}
              >
                <Input />
              </NoSidePaddingFormItem>
            </StyledSpace>

            <StyledSpace align="start">
              <NoSidePaddingFormItem
                name={['ClientId']}
                label={<Translated id="calendarEventConfigurations.clientId" />}
              >
                <Input />
              </NoSidePaddingFormItem>
            </StyledSpace>

            <StyledSpace align="start">
              <NoSidePaddingFormItem
                name={['ClientSecret']}
                label={<Translated id="calendarEventConfigurations.clientSecret" />}
              >
                <Input />
              </NoSidePaddingFormItem>
            </StyledSpace>
          </>
        )}

        <Divider />

        {/* Action Buttons */}
        <NoBottomMarginFormItem>
          <StyledButton type="primary" htmlType="submit">
            <Translated id="app.save" />
          </StyledButton>
        </NoBottomMarginFormItem>
      </Form>
    ),
    [initialValues, dispatch, intl]
  );

  return useMemo(
    () => ({
      SettingsForm,
      updating,
      error,
    }),
    [SettingsForm, updating, error]
  );
};
