/* eslint-disable no-param-reassign */
import React, {useCallback, useMemo} from 'react';
import {Col, Form, Input, Modal, Row} from 'antd';
import {UserDeleteOutlined} from '@ant-design/icons';
import {Store} from 'antd/lib/form/interface';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {Row as TableRow} from 'react-table';

// Types
import {FormOptions, UseFormProps} from '../../types/Table';

// Models
import {Kiosk} from '../../models/Kiosk';

// Hooks
import {useAppDispatch, useAppSelector} from '../App/useRedux';

// Actions
import {createKiosk, deleteKiosk, deleteKiosks, updateKiosk} from '../../store/Kiosks/Kiosks.redux';

// Components
import {Translated} from '../../components/UI/Core';
import {Spinner} from '../../components/UI/Spinner/Spinner';

const { confirm } = Modal;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface KiosksReduxProps {
  isEditing?: boolean;
  initialValues?: Store;
}

// Hook
export const useKiosksRedux = <T extends object>({ isEditing, initialValues }: KiosksReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ kiosks }) => kiosks?.updating ?? false);
  const error = useAppSelector(({ kiosks }) => kiosks?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (kiosk: Kiosk) => {
      if (isEditing) {
        kiosk.Texts = [];
        kiosk.TenantId = initialValues?.TenantId;
        dispatch(updateKiosk(kiosk));
      } else {
        dispatch(createKiosk(kiosk));
      }
    },
    [dispatch, isEditing, initialValues]
  );

  // Components
  const CreateKioskForm = useCallback(
    ({ form }: UseFormProps<T>) => {
      return (
        <StyledForm
          form={form}
          layout="vertical"
          onFinish={(values) => onSubmit(values as Kiosk)}
          initialValues={initialValues}
        >
          <Spinner spinning={updating}>
            <Form.Item name="Id" hidden>
              <Input />
            </Form.Item>
            <Row>
              <Col>
                <Form.Item
                  name="Name"
                  label={<Translated id="kiosks.name" />}
                  rules={[{ required: true, message: intl.formatMessage({ id: 'kiosks.form.warnings.name' }) }]}
                >
                  <Input placeholder="kiosk name" />
                </Form.Item>
              </Col>
            </Row>
          </Spinner>
        </StyledForm>
      );
    },
    [onSubmit, updating, initialValues, intl]
  );
  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'Kiosks',
        Form: CreateKioskForm,
        labels: {
          createButton: <Translated id="kiosks.form.create" />,
          drawerForm: <Translated id={isEditing ? 'kiosks.form.edit' : 'kiosks.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateKioskForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (kiosk: Kiosk | undefined) => {
      if (kiosk) {
        confirm({
          title: intl.formatMessage({
            id: 'kiosks.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'kiosks.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteKiosk(kiosk)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const kiosks = selectedFlatRows.map((d) => d.original) as Kiosk[];
      if (kiosks) {
        confirm({
          title: intl.formatMessage({
            id: 'kiosks.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'kiosks.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteKiosks(kiosks)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm]
  );
};
