import { AxiosResponse } from 'axios';

// Models
import { ApiResponse } from '../models/ApiResponse';
import { Tenant } from '../models/Tenant';
import { Company } from '../models/Company';
import { Location } from '../models/Location';
import { ServiceConfiguration, ServiceConfigurationResponse } from '../models/ServiceConfiguration';
import { ScheduleConfiguration, ScheduleConfigurationResponse } from '../models/ScheduleConfiguration';
import { AccessProfile, AccessProfileResponse } from '../models/AccessProfile';
import { IdentifierType, IdentifierTypeResponse } from '../models/IdentifierType';
import { IdentityUser, IdentityUserResponse } from '../models/IdentityUser';
import { ApiClient } from '../models/ApiClient';
import { IdentityRoleResponse } from '../models/IdentityRole';
import { DashboardDataResponse } from '../models/Dashboard/ApiResponses/DashboardDataResponse';

// Services
import { apiService } from '../services/ApiService/ApiService';
import { Kiosk } from '../models/Kiosk';
import { ApiConfiguration, ApiConfigurationResponse } from '../models/ApiConfiguration';
import { AzureTenant } from '../models/AzureTenant';
import { CalendarEventConfiguration } from '../models/CalendarEventConfiguration';
import { Action } from '../models/Action';

// Generics
const autoResolving =
  <A extends any[], T>(fn: (...a: A) => Promise<AxiosResponse<ApiResponse<T>>>) =>
  async (...args: A): Promise<ApiResponse<T>> =>
    (await fn(...args)).data;

// Endpoints
export const ApiEndpoints = {
  // Tenants
  tenants: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Tenant>>>({
        url: 'Tenants',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Tenant>>({
        url: `Tenants/${id}`,
      })
    ),
    identity: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Tenant>>>({
        url: 'Tenants/Identity',
        params: { index, size, orderBy, desc, search },
      })
    ),
    communication: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Tenant>>>({
        url: 'Tenants/Communication',
        params: { index, size, orderBy, desc, search },
      })
    ),
  },

  // Companies
  companies: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Company>>>({
        url: 'Companies',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Company>>({
        url: `Companies/${id}`,
      })
    ),
  },

  // Locations
  locations: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Location>>>({
        url: 'Locations',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Location>>({
        url: `Locations/${id}`,
      })
    ),
  },

  // ScheduleConfigurations
  scheduleConfigurations: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<ScheduleConfiguration>>>({
        url: 'ScheduleConfigurations',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<ScheduleConfigurationResponse>>({
        url: `ScheduleConfigurations/${id}`,
      })
    ),
  },

  // ServiceConfigurations
  serviceConfigurations: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<ServiceConfiguration>>>({
        url: 'ServiceConfigurations',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<ServiceConfigurationResponse>>({
        url: `ServiceConfigurations/${id}`,
      })
    ),
  },

  // ApiConfigurations
  apiConfigurations: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<ApiConfiguration>>>({
        url: 'ApiConfigurations',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<ApiConfigurationResponse>>({
        url: `ApiConfigurations/${id}`,
      })
    ),
  },

  // AccessProfiles
  accessProfiles: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<AccessProfile>>>({
        url: 'AccessProfiles',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<AccessProfileResponse>>({
        url: `AccessProfiles/${id}`,
      })
    ),
  },

  // IdentifierTypes
  identifierTypes: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<IdentifierType>>>({
        url: 'IdentifierTypes',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<IdentifierTypeResponse>>({
        url: `IdentifierTypes/${id}`,
      })
    ),
  },

  // Kiosks
  kiosks: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Kiosk>>>({
        url: 'KioskConfigurations',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Kiosk>>({
        url: `KioskConfigurations/${id}`,
      })
    ),
  },

  // IdentityUsers
  identityUsers: {
    list: autoResolving(({ pageIndex, pageSize: size, sortedBy: orderBy, desc, search, roles } = {}) =>
      apiService.execute<ApiResponse<Array<IdentityUser>>>({
        url: `IdentityUsers`,
        params: { pageIndex, size, orderBy, desc, search, roles },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<IdentityUserResponse>>({
        url: `IdentityUsers/${id}`,
      })
    ),
  },

  // Clients
  apiClients: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<ApiClient>>>({
        url: `ApiClients`,
        params: { index, size, orderBy, desc, search },
      })
    ),
  },

  // IdentityRoles
  identityRoles: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<IdentityRoleResponse>>>({
        url: `IdentityRoles`,
        params: { index, size, orderBy, desc, search },
      })
    ),
  },

  // Dashboard
  dashboard: {
    data: autoResolving(() =>
      apiService.execute<ApiResponse<DashboardDataResponse>>({
        url: `Dashboard/Data/BluePortal`,
      })
    ),
  },

  // AzureTenants
  azureTenants: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<AzureTenant>>>({
        url: 'AzureTenants',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<AzureTenant>>({
        url: `AzureTenants/${id}`,
      })
    ),
  },

  // CalendarEventConfiguration
  calendarEventConfigurations: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<CalendarEventConfiguration>>>({
        url: 'CalendarEventConfigurations',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<CalendarEventConfiguration>>({
        url: `CalendarEventConfigurations/${id}`,
      })
    ),
  },

  // Actions
  Actions: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Action>>>({
        url: 'Actions',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Action>>({
        url: `Actions/${id}`,
      })
    ),
  },
};
