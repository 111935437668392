import React from 'react';
import { Col, Row } from 'antd';

// Components
import styled from 'styled-components';
import { Widget } from '../../components/UI/Widget/Widget';

import { Container } from '../../components/UI/Base';

import { ManagementStats } from '../../components/Dashboard/WelcomeCard/ManagementStats';
import { BillingStats } from '../../components/Dashboard/WelcomeCard/BillingStats';

import { EnvironmentStats } from '../../components/Dashboard/Environments/EnvironmentStats';

// Hooks
import { useDashboardData } from '../../hooks/Dashboard/useDashboard';
import { EnvironmentFilters } from '../../components/Dashboard/Environments/EnvironmentFilters';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

// Styled
const WelcomeWidget = styled(Widget)`
  margin-top: 20px;

  & .ant-card-head {
    background: #fafafa;
  }

  & .ant-card-body {
    padding: 16px 24px;
  }
`;
const ActivityWidget = styled(Widget)`
  & .ant-card-head {
    background: #fafafa;
  }

  & .ant-card-body {
    padding: 16px 24px;
  }
`;
const StyledRow = styled(Row)`
  min-height: 200px;
`;

export const DashboardPage = () => {
  const {
    getManagementStatsProps,
    getSiteMetricsProps,
    getEnvironmentStatsProps,
    getBillingStatsProps,
    getActivityHistoryProps,
    getFiltersProps,
  } = useDashboardData();

  return (
    <>
      <BreadcrumbMenu />

      <Container className="gx-profile-content">
        <WelcomeWidget styleName="gx-card-widget">
          <StyledRow>
            <ManagementStats {...getManagementStatsProps()} />
            <EnvironmentFilters {...getFiltersProps()} />
            <BillingStats {...getBillingStatsProps()} />
          </StyledRow>
        </WelcomeWidget>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <EnvironmentStats {...getEnvironmentStatsProps()} />
          </Col>
        </Row>
      </Container>
    </>
  );
};
